<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you were looking for doesn't exist.</p>
    <router-link to="/">Go back home</router-link>
  </div>
</template>

<script>
export default {
  name: "ErrorView",
};
</script>
